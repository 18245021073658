import { Action, on } from '@ngrx/store';

import { createImmerReducer } from 'app/utils/createImmerReducer';

import * as actions from './actions';
import { HomePageState } from './types';

const SAVED_ROOMS_PAGE_SIZE = 20;
export const ALTERNATE_HOST_CANDIDATES_PAGE_SIZE = 5;
export const USERS_FROM_COMPANY_PAGE_SIZE = 20;

export const initialState: HomePageState = {
  savedRooms: {
    data: [],
    page: 0,
    size: SAVED_ROOMS_PAGE_SIZE,
    loading: false,
    hasMore: true,
  },
  alternateHostCandidates: {
    data: [],
    page: 0,
    size: ALTERNATE_HOST_CANDIDATES_PAGE_SIZE,
    loading: false,
    hasMore: false,
  },
  room: null,
  loading: false,
  saveSettingsError: null,
  saveSettingsSuccess: null,
  saveAlternateHostsError: null,
  saveAlternateHostsSuccess: null,
  editRoomError: null,
  editRoomSuccess: null,
  deleteRoomError: null,
  deleteRoomSuccess: null,
  usersFromCompany: {
    data: [],
    page: 0,
    size: USERS_FROM_COMPANY_PAGE_SIZE,
    loading: false,
    hasMore: true,
  },
};

const _reducer = createImmerReducer(
  initialState,

  on(actions.INIT, (_: HomePageState) => {
    return initialState;
  }),

  on(actions.FETCH_SAVED_ROOMS_REQ, (state: HomePageState, { page, size }) => {
    const { savedRooms } = state;
    if (page === 0) {
      savedRooms.hasMore = true;
      savedRooms.data = [];
    }
    savedRooms.page = page;
    savedRooms.size = size;
    savedRooms.loading = true;
    return state;
  }),

  on(actions.FETCH_SAVED_ROOMS_RSP, (state: HomePageState, { rooms }) => {
    const { savedRooms } = state;

    if (rooms.length < savedRooms.size) {
      savedRooms.hasMore = false;
    }

    if (savedRooms.page === 0) {
      savedRooms.data = rooms;
    } else {
      savedRooms.data = [...savedRooms.data, ...rooms];
    }
    savedRooms.loading = false;
    return state;
  }),

  on(actions.FETCH_SAVED_ROOMS_ERR, (state: HomePageState) => {
    const { savedRooms } = state;
    savedRooms.loading = false;
    return state;
  }),

  on(actions.EDIT_ROOM_RSP, (state: HomePageState, { room }) => {
    state.room = room;
    state.editRoomSuccess = true;
    return state;
  }),

  on(actions.EDIT_ROOM_ERR, (state: HomePageState) => {
    state.editRoomError = true;
    return state;
  }),

  on(actions.DELETE_ROOM_RSP, (state: HomePageState) => {
    state.deleteRoomSuccess = true;
    return state;
  }),

  on(actions.DELETE_ROOM_ERR, (state: HomePageState) => {
    state.deleteRoomError = true;
    return state;
  }),

  on(actions.CLEAR_DELETE_RSP, (state: HomePageState) => {
    state.deleteRoomSuccess = false;
    state.deleteRoomError = false;
    return state;
  }),

  on(actions.SAVE_SOCIAL_SETTINGS_RSP, (state: HomePageState) => {
    state.saveSettingsSuccess = true;
    return state;
  }),

  on(actions.SAVE_SOCIAL_SETTINGS_ERR, (state: HomePageState) => {
    state.saveSettingsError = true;
    return state;
  }),

  on(actions.RESET_SOCIAL_PAGE, (state: HomePageState) => {
    state.saveSettingsError = null;
    state.saveSettingsSuccess = null;
    state.editRoomError = null;
    state.editRoomSuccess = null;
    return state;
  }),

  on(actions.FETCH_ALTERNATE_HOSTS_CANDIDATES_REQ, (state: HomePageState, { paging }) => {
    const { alternateHostCandidates } = state;
    const { page, size } = paging;
    if (paging.page === 0) {
      alternateHostCandidates.hasMore = true;
      alternateHostCandidates.data = [];
    }
    alternateHostCandidates.page = page;
    alternateHostCandidates.size = size;
    alternateHostCandidates.loading = true;
    return state;
  }),

  on(actions.FETCH_ALTERNATE_HOSTS_CANDIDATES_ROOMSET_REQ, (state: HomePageState, { paging }) => {
    const { alternateHostCandidates } = state;
    const { page, size } = paging;
    if (paging.page === 0) {
      alternateHostCandidates.hasMore = true;
      alternateHostCandidates.data = [];
    }
    alternateHostCandidates.page = page;
    alternateHostCandidates.size = size;
    alternateHostCandidates.loading = true;
    return state;
  }),

  on(actions.FETCH_ALTERNATE_HOSTS_CANDIDATES_RSP, (state: HomePageState, { alternateHostCandidates }) => {
    state.alternateHostCandidates.hasMore = alternateHostCandidates.length >= state.alternateHostCandidates.size;

    state.alternateHostCandidates.data = [...state.alternateHostCandidates.data, ...alternateHostCandidates];
    state.alternateHostCandidates.loading = false;
    return state;
  }),

  on(actions.FETCH_ALTERNATE_HOSTS_CANDIDATES_ERR, (state: HomePageState) => {
    const { alternateHostCandidates } = state;
    alternateHostCandidates.loading = false;
    return state;
  }),

  on(actions.RESET_ALTERNATE_HOSTS_CANDIDATES, (state: HomePageState) => {
    const { alternateHostCandidates } = state;
    alternateHostCandidates.loading = false;
    alternateHostCandidates.data = [];
    alternateHostCandidates.hasMore = true;
    alternateHostCandidates.page = 0;
    return state;
  }),

  on(actions.UPDATE__ALTERNATE_HOSTS_CANDIDATES_RSP, (state: HomePageState) => {
    state.saveAlternateHostsSuccess = true;
    state.saveAlternateHostsError = false;
    return state;
  }),

  on(actions.UPDATE__ALTERNATE_HOSTS_CANDIDATES_ERR, (state: HomePageState) => {
    state.saveAlternateHostsSuccess = false;
    state.saveAlternateHostsError = true;
    return state;
  }),

  on(actions.GET_USERS_FROM_COMPANY_REQ, (state: HomePageState, { paging }) => {
    const { usersFromCompany } = state;
    const { page, size } = paging;
    if (page === 0) {
      usersFromCompany.hasMore = true;
      usersFromCompany.data = [];
    }
    usersFromCompany.page = page;
    usersFromCompany.size = size;
    usersFromCompany.loading = true;
    return state;
  }),

  on(actions.GET_USERS_FROM_COMPANY_RSP, (state: HomePageState, { users }) => {
    const { usersFromCompany } = state;

    if (users.length === 0) {
      usersFromCompany.hasMore = false;
    }

    if (usersFromCompany.page === 0) {
      usersFromCompany.data = users;
    } else {
      usersFromCompany.data = [...usersFromCompany.data, ...users];
    }
    usersFromCompany.loading = false;
    return state;
  }),

  on(actions.GET_USERS_FROM_COMPANY_ERR, (state: HomePageState) => {
    const { usersFromCompany } = state;
    usersFromCompany.loading = false;
    return state;
  }),
);

export const homePageReducer = (state: HomePageState | undefined, action: Action): ReturnType<typeof _reducer> =>
  _reducer(state, action);
